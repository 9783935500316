const Loading = () => (
  <div className="flex flex-row items-center justify-center p-12">
    <div>
      <div className="loading-state loading-1" />
      <div className="loading-state loading-2" />
      <div className="loading-state loading-3" />
      <div className="loading-state loading-4" />
      <div className="loading-state loading-5" />
    </div>
  </div>
);

export default Loading;
