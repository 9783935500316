import labels from "@/config/labels";

import ConsensusPicksSelect from "./components/ConsensusPicksSelect";

const ConsensusPicks = ({ onSelectGame }) => {
  return (
    <div className="lg:sticky lg:top-[6.5rem] lg:py-10">
      <p className="mb-2 text-xs font-medium uppercase tracking-wider text-darker-grey">
        {labels.freeBettingPicksHeader}
      </p>
      <ConsensusPicksSelect onSelectGame={onSelectGame} />
    </div>
  );
};

export default ConsensusPicks;
