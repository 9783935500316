import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";

import Icon from "@/components/icon/Icon";
import Loading from "@/components/Loading";
import labels from "@/config/labels";
import { CONSENSUS_PICKS_MENU } from "@/config/menus";
import { useAppContext } from "@/hooks/useAppContext";
import { useGamesContext } from "@/hooks/useGamesContext";
import { useKeyPress } from "@/hooks/useKeyPress";
import caretDark from "@/public/caret-down-dark.svg";
import caretLight from "@/public/caret-down-light.svg";

import GameCard from "./GameCard";

const ConsensusPicksSelect = ({ onSelectGame }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [parentElement, setParentElement] = useState(null);
  const popoverParentId = "league-select";
  const {
    games,
    isGamesLoading,
    soccerGames,
    isSoccerGamesLoading,
    collegeGames,
    isCollegeGamesLoading,
    isWbnaGamesLoading,
    wnbaGames,
  } = useGamesContext();
  const { selectedLeague, setSelectedLeague } = useAppContext();

  useEffect(() => {
    // Set parent element to popover after javascript has been
    // hydrated on the client side and the document exists
    const popoverParentElement = document.getElementById(popoverParentId);
    if (popoverParentElement) {
      setParentElement(popoverParentElement);
    }
  }, [popoverParentId]);

  // Close popover if open and escape key is pressed
  useKeyPress("Escape", isPopoverOpen, () => setIsPopoverOpen(false));

  const isCollegeLeague =
    selectedLeague === "ncaaf" || selectedLeague === "ncaab";
  const isSoccerLeague = selectedLeague === "soccer";
  const isWnbaLeague = selectedLeague === "wnba";
  const isLoading = isWnbaLeague
    ? isWbnaGamesLoading
    : isCollegeLeague
      ? isCollegeGamesLoading
      : isSoccerLeague
        ? isSoccerGamesLoading
        : isGamesLoading;

  const getSelectedGames = (
    games,
    selectedLeague,
    soccerGames,
    collegeGames
  ) => {
    switch (selectedLeague) {
      case "soccer":
        return soccerGames;
      case "wnba":
        return wnbaGames;
      case "ncaaf":
      case "ncaab":
        return collegeGames?.[selectedLeague];
      default:
        return games?.[selectedLeague];
    }
  };
  const selectedGames = getSelectedGames(
    games,
    selectedLeague,
    soccerGames,
    collegeGames
  );

  // Set selected league and close popover
  function onSelect(option) {
    setSelectedLeague(option);
    setIsPopoverOpen(false);
  }

  return (
    <div className="mb-2" id={popoverParentId}>
      {parentElement && (
        <Popover
          parentElement={parentElement}
          isOpen={isPopoverOpen}
          padding={6}
          positions={["bottom"]}
          reposition={false}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={({ parentRect }) => (
            <div
              className="flex flex-col rounded-lg border border-grey-200 bg-white py-2"
              style={{ width: parentRect.width }}
            >
              {Object.values(CONSENSUS_PICKS_MENU).map((option) => {
                const optionClassName =
                  option?.id === selectedLeague
                    ? "flex flex-row items-center py-1.5 px-3 bg-select-blue text-white focus:outline-0 focus:bg-select-blue hover:outline-0 hover:bg-select-blue"
                    : "flex flex-row items-center py-1.5 px-3 bg-white text-nav-blue focus:outline-0 focus:bg-select-hover hover:outline-0 hover:bg-select-hover";
                return (
                  <button
                    key={option?.id}
                    aria-label={option?.name}
                    className={optionClassName}
                    onClick={() => onSelect(option?.id)}
                  >
                    <Icon
                      className="mr-2"
                      height={"16px"}
                      name={`${option?.id}Select`}
                      width={"20px"}
                      color="currentColor"
                    />
                    {option?.name}
                  </button>
                );
              })}
            </div>
          )}
        >
          <button
            aria-label={CONSENSUS_PICKS_MENU[selectedLeague]?.name}
            className="flex h-[50px] w-full items-center justify-between rounded-full border border-grey-200 bg-white px-5 py-2 focus:outline-4 focus:outline-select-blue"
            onClick={() => setIsPopoverOpen((value) => !value)}
            style={{
              outline: isPopoverOpen ? "4px auto rgba(38, 132, 255)" : null,
            }}
          >
            <div className="flex flex-row items-center text-base text-darker">
              <Icon
                aria-label={CONSENSUS_PICKS_MENU[selectedLeague]?.name}
                className="mr-2"
                height={"16px"}
                name={`${CONSENSUS_PICKS_MENU[selectedLeague]?.id}Select`}
                width={"20px"}
                color="currentColor"
              />
              {CONSENSUS_PICKS_MENU[selectedLeague]?.name}
            </div>
            <Image
              alt="Dropdown menu indicator for sports betting information"
              height={20}
              src={isPopoverOpen ? caretDark : caretLight}
              width={20}
            />
          </button>
        </Popover>
      )}
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="games-sidebar my-4 grid grid-cols-1 content-start gap-2 overflow-y-auto pr-1">
          {selectedGames ? (
            Object.values(selectedGames).map((game) => (
              <GameCard
                key={game?.gameKey}
                game={game}
                onSelectGame={onSelectGame}
              />
            ))
          ) : (
            <div className="mt-2 rounded-lg border border-card-border bg-off-white p-4 text-center font-body tracking-[.01em] text-card-dark">
              {labels.freeBettingPicksNoGames}{" "}
              <Link
                className="underline"
                href={CONSENSUS_PICKS_MENU[selectedLeague].noGameUrl ?? ""}
              >
                {`${
                  selectedLeague === "all" ? "" : selectedLeague.toUpperCase()
                } ${labels.freeBettingGuide}`}
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ConsensusPicksSelect;
