import { useCallback, useEffect } from "react";

// A hook that runs a callback function based on a target key
// being pressed and a condition being met

export function useKeyPress(targetKey, conditional, callback) {
  const keyDownHandler = useCallback(
    (event) => {
      if (event.key === targetKey && conditional) {
        callback();
      }
    },
    [targetKey, conditional, callback]
  );
  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);
}
