import Image from "next/image";
import Link from "next/link";

import { GameStartTimeDisplay } from "@/components/GameStartTimeDisplay";
import { LiveBox } from "@/components/LiveBox";
import { GAME_STATUS_FILTER } from "@/config/constants";
import labels from "@/config/labels";
import { useLocalTime } from "@/hooks/useLocalTime";
import {
  getDateDifference,
  getESTTime,
  getGameCardAriaLabel,
} from "@/utilities/dates";

const Badge = ({ isAway = false, isPick, value }) => {
  let badgeClasses =
    "w-[40px] rounded-lg py-2 ml-2 mr-4 bg-border-grey text-xs leading-[0.5rem] text-center";
  if (isAway && !isPick) {
    badgeClasses =
      "w-[40px] rounded-lg py-2 ml-4 mr-2 bg-border-grey text-xs leading-[0.5rem] text-center";
  } else if (isAway && isPick) {
    badgeClasses =
      "w-[40px] rounded-lg py-2 ml-4 mr-2 bg-teal text-xs text-white leading-[0.5rem] text-center";
  } else if (!isAway && isPick) {
    badgeClasses =
      "w-[40px] rounded-lg py-2 ml-2 mr-4 bg-teal text-xs text-white leading-[0.5rem] text-center";
  }
  return <div className={badgeClasses}>{value}</div>;
};

const GameCard = ({ game, onSelectGame }) => {
  const startTime = useLocalTime({
    startTime: game?.startTime,
  });
  const { daysDiff } = getDateDifference(startTime);
  const shouldRenderLiveBox = Boolean(
    getESTTime() >= startTime &&
      daysDiff >= -1 &&
      !GAME_STATUS_FILTER.includes(game?.gameStatus ?? "")
  );

  return (
    <div className="rounded-lg border border-card-border bg-off-white p-4 font-body tracking-[.01em] text-card-dark">
      <div className="mb-4 flex flex-row items-center justify-center whitespace-nowrap">
        <Image
          className="mr-2 h-[32px] w-[32px]"
          alt={game?.awayTeam?.name}
          src={game?.awayTeam?.logo}
          height={32}
          width={32}
        />
        {game?.awayTeam?.abbreviation}
        {startTime && !shouldRenderLiveBox ? (
          <GameStartTimeDisplay
            startTime={startTime}
            isRow={false}
            showLegend={false}
          />
        ) : (
          <LiveBox className={"mx-7"} />
        )}

        {game?.homeTeam?.abbreviation}
        <Image
          className="ml-2 h-[32px] w-[32px]"
          alt={game?.homeTeam?.name}
          src={game?.homeTeam?.logo}
          height={32}
          width={32}
        />
      </div>
      {game?.gameCard && (
        <>
          <div className="mb-2 flex flex-row items-center justify-center">
            {game?.gameCard?.moneyLineAwayOdds === "0"
              ? "-"
              : game?.gameCard?.moneyLineAwayOdds}
            <Badge
              isAway
              isPick={game?.gameCard?.moneyLine?.pick == "away"}
              value={game?.gameCard?.moneyLine?.away}
            />
            <Badge
              isPick={game?.gameCard?.moneyLine?.pick == "home"}
              value={game?.gameCard?.moneyLine?.home}
            />
            {game?.gameCard?.moneyLineHomeOdds === "0"
              ? "-"
              : game?.gameCard?.moneyLineHomeOdds}
          </div>
          <div className="mb-2 flex flex-row items-center justify-center">
            {game?.gameCard?.pointSpreadAwaySpread === "0"
              ? "-"
              : game?.gameCard?.pointSpreadAwaySpread}
            <Badge
              isAway
              isPick={game?.gameCard?.pointSpread?.pick == "away"}
              value={game?.gameCard?.pointSpread?.away}
            />
            <Badge
              isPick={game?.gameCard?.pointSpread?.pick == "home"}
              value={game?.gameCard?.pointSpread?.home}
            />
            {game?.gameCard?.pointSpreadHomeSpread === "0"
              ? "-"
              : game?.gameCard?.pointSpreadHomeSpread}
          </div>
          <div className="mb-2 flex flex-row items-center justify-center">
            {game?.gameCard?.overUnderOverPoints === "0"
              ? "-"
              : game?.gameCard?.overUnderOverPoints}
            <span className="trend trend-over ml-1" />
            <Badge
              isAway
              isPick={game?.gameCard?.overUnder?.pick == "away"}
              value={game?.gameCard?.overUnder?.away}
            />
            <Badge
              isPick={game?.gameCard?.overUnder?.pick == "home"}
              value={game?.gameCard?.overUnder?.home}
            />
            <span className="trend trend-under mr-1" />
            {game?.gameCard?.overUnderUnderPoints === "0"
              ? "-"
              : game?.gameCard?.overUnderUnderPoints}
          </div>
          <Link
            aria-label={getGameCardAriaLabel(
              game?.awayTeam?.name,
              game?.homeTeam?.name,
              game?.startTime
            )}
            href={`/${game?.league}/matchup/${game?.gameKey}`}
            onClick={() => onSelectGame && onSelectGame()}
          >
            <p className="mt-4 text-center font-heading text-[0.9rem] font-bold text-link-blue">
              See {game?.gameCard?.siteCount}{" "}
              {labels.gameCardSiteFixPostfix
                .replace("%awayTeam", game?.awayTeam?.abbreviation)
                .replace("%homeTeam", game?.homeTeam?.abbreviation)}
            </p>
          </Link>
        </>
      )}
      {!game?.gameCard && (
        <p className="mt-4 text-center">{labels.gameCardNoPicks}</p>
      )}
    </div>
  );
};

export default GameCard;
